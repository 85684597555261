.btn-component {
  background-color: var(--secondary-color);
  border: none;
  color: var(--lightGray-color);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: clamp(0.4rem, 1.1vw, 5rem);
  border-radius: 7px;
  text-transform: uppercase;
  transition-duration: 0.4s;
  max-width: 40%;
  height: 3rem;
  width: 40%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: var(--light-orange);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  &:active {
    transition-duration: 0s;
  }
}
