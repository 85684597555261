.app {
  &__navbar {
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 100;

    &-links {
      flex: 1;
      display: flex;
      max-width: 60%;
      justify-content: flex-end;
      align-items: center;
      list-style: none;

      li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div {
          width: 5px;
          height: 5px;
          background: transparent;
          border-radius: 50%;
          margin-bottom: 5px;
        }

        a {
          color: var(--primary-color);
          font-size: 1rem;
          text-decoration: none;
          flex-direction: column;
          text-transform: uppercase;
          min-width: 60px;
          font-weight: 800;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }

        &:hover {
          div {
            background-color: var(--secondary-color);
          }
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }

      @media screen and (max-width: 450px) {
        padding: 0.2rem 2rem;
      }
    }

    &-menu {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--secondary-color);
      z-index: 100;

      svg {
        width: 70%;
        height: 70%;
        color: var(--primary-color);
      }

      div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 100;

        padding: 1rem;

        width: 100vw;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--lightGray-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
          width: 35px;
          height: 35px;
          color: var(--secondary-color);
          margin: 0.5rem 1rem;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;

          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          li {
            margin: 1rem;

            a {
              color: var(--primary-color);
              text-decoration: none;

              transition: all 0.3s ease-in-out;
              text-transform: uppercase;
              font-weight: 800;

              &:hover {
                color: var(--secondary-color);
              }
            }
          }
        }

        @media screen and (min-width: 1024px) {
          display: none;
        }
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 1rem 2rem;
    }
  }
}
