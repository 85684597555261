@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
/*@layer base {*/
/*    :root {*/
/*        height: 100%;*/
/*        --color-primary: hsl(280.853, 100%, 68%);*/
/*    }*/

/*    !* These heights are only there so the example can be centered vertically. *!*/
/*    body, #root {*/
/*        height: 100%;*/
/*    }*/
/*}*/

:root {
    --font-base: "Roboto Mono", "DM Sans", "Courgette", sans-serif;

    --primary-color: #0C2233;
    --secondary-color: #F87D42;
    --light-orange: rgb(213, 94, 31);
    --black-color: #030303;
    --lightGray-color: #EEEEEE;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: rgba(255, 255, 255, 0.49);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: var(--secondary-color);
}
