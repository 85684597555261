#home {
  position: relative;
  background: url("../../assets/background_hero.jpg") repeat center;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.8;
    z-index: 2;
  }

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 93vh;
  
  @media screen and (min-width: 1301px) {
    padding: 0 5rem;
    justify-content: space-evenly;

    img {
      max-width: 100%;
      min-width: 60vh;
      min-height: 60vh;
    }
  }

  @media screen and (max-width: 1300px) and (min-width: 900px) {
    padding: 0 5rem;
    img {
      max-width: 100%;
      min-width: 30vh;
      min-height: 30vh;
    }
  }

  @media screen and (max-width: 900px) and (min-width: 451px) {
    padding: 0 0.5rem;
    justify-content: space-evenly;
    height: 58vh;

    img {
      max-width: 100%;
      max-height: 56vh;
    }
  }

  @media screen and (max-width: 450px) {
    height: 58vh;

    img {
      min-width: 32vh;
      min-height: 47vh;
      max-height: 56vh;
    }
  }
}

.app__header-info {
  max-width: 35%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  min-height: 60%;
  justify-content: space-evenly;
  line-height: 1.2;

  .head-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .head-text {
    font-size: clamp(0.5rem, 3vw, 5rem);
    font-weight: 800;
    text-align: center;
    color: var(--lightGray-color);
    text-transform: capitalize;
    padding-bottom: 4rem;

    span {
      color: var(--secondary-color);
    }
  }

  .p-text {
    font-size: clamp(0.5rem, 1.5vw, 5rem);
    text-align: center;
    padding-bottom: 2rem;
    color: var(--lightGray-color);
    line-height: 1.2;
  }

  @media screen and (max-width: 1300px) and (min-width: 1101px) {
    max-width: 35%;
  }

  @media screen and (max-width: 1100px) and (max-width: 901px) {
    max-width: 50%;
    min-width: 15vw;
    padding: 0 2rem;
    min-height: 60%;
  }

  @media screen and (max-width: 900px) and (min-width: 766px) {
    max-width: 50%;
    min-width: 15vw;
    padding: 0 0;
    min-height: 60%;

    .head-text {
      padding-bottom: 2rem;
    }

    .p-text {
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: 765px) and (min-width: 451px) {
    display: none;
    max-width: 50%;
    min-width: 15vw;
    padding: 0 0;
    min-height: 60%;

    .head-text {
      padding-bottom: 2rem;
    }

    .p-text {
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: 450px) {
    display: none;
    max-width: 50%;
    min-width: 15vw;
    padding: 0 0;
    min-height: 60%;

    .head-btns {
      height: 20px;
    }

    .head-text {
      padding-bottom: 2rem;
    }

    .p-text {
      padding-bottom: 1rem;
    }
  }
}


.app__header-hero {
  display: flex;
  padding: 0;
  margin: 0;
  max-width: 60vh;
  align-self: flex-end;
  align-items: center;
  justify-items: center;
  justify-self: flex-end;

  img {
    max-width: 100%;
    z-index: 4;
  }

  @media screen and (max-width: 450px) {
    align-self: flex-end;
    justify-self: flex-end;
  }
}
